<template>
  <div>
    <div class="vipClub">
      <div class="box-VipClub">
        <div class="box-VipClub-title">
          <h4>
            <strong>{{ $t('Member.142') }} EUSD</strong>
          </h4>
        </div>
        <div class="box-VipClub-content">
          <span class="h1 animation-expandOpen game-title-2" v-if="Balance">
            {{ Balance['EUSD'] ? Balance['EUSD'].toFixed(2) : 0 }}</span
          >
        </div>
      </div>
      <div class="box-VipClub" v-if="checkEBP">
        <div class="box-VipClub-title">
          <h4>
            <strong>{{ $t('Member.142') }} EBP</strong>
          </h4>
        </div>
        <div class="box-VipClub-content">
          <span class="h1 animation-expandOpen game-title-2" v-if="Balance">
            {{ Balance['EBP'] ? Balance['EBP'].toFixed(2) : 0 }}</span
          >
        </div>
      </div>
    </div>
    <div class="vipClub">
      <div class="box-VipClub">
        <div class="box-VipClub-title">
          <h4>
            <strong>{{ $t('Member.143') }}</strong>
          </h4>
        </div>
        <div class="box-VipClub-content">
          <span class="h1 animation-expandOpen game-title-2" v-if="Total">{{
            Total.member
          }}</span>
        </div>
      </div>
      <div class="box-VipClub">
        <div class="box-VipClub-title">
          <h4>
            <strong>{{ $t('Vipclup.230') }}</strong>
          </h4>
        </div>
        <div class="box-VipClub-content member-ib">
          <div class="img-div" v-if="Agency.PackageImage">
            <!-- <img :src="Agency.PackageImage" alt="" /> -->
          </div>
          <div class="detail-div" v-if="Agency.PackageName">
            <span class="mx-2">{{ Agency.PackageName }}</span>
            <button class="btn btn-success" @click="openListPackage()">
              {{ $t('Member.162') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="vipClub">
      <div class="box-VipClub">
        <div class="box-VipClub-title">
          <h4>
            <strong>{{ $t('Vipclup.231') }}</strong>
          </h4>
        </div>
        <div class="box-VipClub-content" v-if="Agency.Static">
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Vipclup.232') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.PendingLastWeek }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Vipclup.233') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.PendingThisWeek }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Transaction.97') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.TotalTrade }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Member.149') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.VolumeTrade }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Vipclup.234') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.Branch_Trade[1] }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Vipclup.235') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.Branch_Trade[2] }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Vipclup.236') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.Branch_Trade[3] }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-VipClub" v-if="Info && Info.level == 1">
        <div class="box-VipClub-title">
          <h4>
            <strong>{{ $t('Transaction.59') }}</strong>
          </h4>
        </div>
        <div class="box-VipClub-content" v-if="Agency.Static">
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Transaction.96') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">
                {{
                  Agency.Static && Agency.Static.balanBonus
                    ? Agency.Static.balanBonus
                    : '0'
                }}
              </div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">{{ $t('Transaction.97') }}</div>
            </div>
            <div class="right-content">
              <div class="game-title">
                {{
                  Agency.Static && Agency.Static.totalTradeBonus
                    ? Agency.Static.totalTradeBonus
                    : '0'
                }}
              </div>
            </div>
          </div>
          <button @click="$bvModal.show('modal-transaction-statistical')">
            {{ $t('Transaction.56') }}
          </button>
        </div>
      </div>
    </div>
    <!-- listPackage -->
    <b-modal id="listPackage" hide-footer hide-header>
      <div class="title">{{ $t('Member.161') }}</div>
      <form id="form-deposit" class="form-horizontal" style="padding: 20px;">
        <div class="row">
          <div
            v-for="list in Agency.AllPackage"
            :key="list.id"
            class="col-lg-3 col-md-6 col-sm-6"
          >
            <div class="list-agency">
              <div class="pricingTable-header">
                <h3 class="title">{{ list.name }}</h3>
              </div>
              <div class="icon">
                <!-- <img :src="list.image" /> -->
              </div>
              <div class="price-value">
                <span class="amount">${{ list.price }}</span>
              </div>
              <!-- <ul class="pricing-content">
                  <li>50GB Disk Space</li>
              </ul> -->
              <div class="pricingTable-signup">
                <button
                  v-if="list.can_buy == 1"
                  @click="onBuyAgency(list.id)"
                  type="button"
                  class="btn-success"
                  :class="list.price == 0 ? 'opacity-0' : ''"
                >
                  {{ $t('Member.162') }}
                </button>
                <button v-else type="button" class="btn-danger" disabled>
                  {{ $t('Member.162') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="btn-close" @click="$bvModal.hide('listPackage')">
        <button>{{ $t('Message.269') }}</button>
      </div>
    </b-modal>
    <Transaction
      :type="'statistical'"
      :action="action"
      @changeAction="changeActionParent"
    />
  </div>
</template>

<script>
  import Transaction from '@/components/modal/Transaction.vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      Transaction,
    },
    data() {
      return {
        mainBalance: 0,
        totalMember: 0,
        totalSubAccount: 0,
        listPackage: '',
        amountWithdraw: 0,
        passwordWithdraw: '',
        action: 6,
      };
    },
    computed: {
      ...mapState(['isLogin']),
      ...mapGetters({
        Agency: 'dashboard/Agency',
        Total: 'dashboard/Total',
        Balance: 'dashboard/Balance',
        Game: 'dashboard/Game',
        Info: 'dashboard/Info',
        ListCoin: 'wallet/ListCoin',
      }),
      checkEBP() {
        for (var coin in this.ListCoin) {
          if (coin === 'EBP') {
            return true;
          }
        }
        return false;
      },
    },
    methods: {
      onBuyAgency: async function(id) {
        const token = await this.genarateCaptChaV3('buyAgency');
        this.$store.dispatch('dashboard/req_buyAgency', {
          token: token,
          id: id,
        });
      },
      openListPackage() {
        this.$bvModal.show('listPackage');
      },
      handleWithdrawBonus: async function() {
        const token = await this.genarateCaptChaV3('deposit');
        this.$store.dispatch('dashboard/req_postWithdrawBonus', {
          token: token,
          amount: this.amountWithdraw,
          password: this.passwordWithdraw,
        });
        this.$finishLoading();
      },
      changeActionParent(action) {
        this.action = action;
      },
    },
    filters: {
      formatNumber(value) {
        if (!value) return 0;
        return new Intl.NumberFormat().format(value);
      },
    },
    created() {
      if (this.isLogin) {
        this.$store.dispatch('dashboard/req_getAgency');
      }
    },
  };
</script>
<style lang="scss">
  #listPackage {
    &::-webkit-scrollbar {
      width: 5px;
    }
    @media (max-width: 991px) {
      padding-left: 5px !important;
    }
    &.modal {
      .modal-dialog {
        max-width: 900px;
        height: auto;
        border-radius: 1.25rem;
        @media (max-width: 767px) {
          margin: 0px;
          margin-top: 50px;
        }
        .modal-content {
          background-color: #17181b;
          border-radius: 1.25rem;
          .modal-body {
            border: 1px solid #fff;
            border-radius: 1.25rem;
            @media (max-width: 991px) {
              height: 100vh;
              overflow-y: auto;
            }
            &::-webkit-scrollbar {
              width: 5px;
            }
          }
        }
      }
    }
  }
</style>
<style scoped lang="scss">
  .btn-close {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    button {
      background-image: conic-gradient(from 1turn, #880202, #c51414);
      border-radius: 2rem;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 7px 15px;
      border: none;
      color: #fff;
    }
  }
  .vipClub {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: 20px auto;
    .box-VipClub {
      width: 48%;
      @media (max-width: 1199px) {
        width: 100%;
        margin-bottom: 20px;
      }
      @media (max-width: 991px) {
        width: 48%;
        padding: 0px 10px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      .box-VipClub-title {
        color: white;
        padding: 10px 10px;
        background-color: #5da000;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        h4 {
          margin-bottom: 0px;
          font-size: 16px;
        }
      }
      .box-VipClub-content {
        margin-bottom: 20px;
        height: auto;
        min-height: 225px;
        background: rgb(30 32 36);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px 0px;
        .game {
          width: 100%;
        }
        button {
          margin-top: 10px;
          height: 2.2rem;
          width: auto;
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #458802, #7bc514);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
      }
    }
  }
  .button-custom {
    height: 30px;
    background: #c8973b;
    border-top-right-radius: 5px;
    border: 0 none !important;
    border-bottom-right-radius: 5px;
    color: white;
    max-width: 40%;
    margin: 0 !important;
    padding: 0 5px;
    font-size: 15px;
  }
  .input-custom {
    width: 60%;
    height: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0 none !important;
    text-align: center;
    font-size: 15px;
    padding: 0;
  }
  .widget {
    min-height: 155px;
  }
  .box-VipClub-content {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .game-title-2 {
    color: #ffa300;
    font-weight: 800;
  }
</style>

<style scoped lang="scss">
  .member-ib {
    display: flex;
  }
  .opacity-0 {
    opacity: 0;
  }
  .list-agency {
    background-color: rgba(45, 48, 53, 0.5);
    text-align: center;
    margin-bottom: 15px;
    padding: 20px 0 35px;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.3);
  }
  .list-agency .icon {
    width: 120px;
    margin: 0 auto 15px;
    min-height: 140px;
  }
  .list-agency .icon img {
    width: 100%;
  }
  .list-agency .title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 35px;
    background: #fff0;
    margin: 0;
  }
  .list-agency .price-value {
    color: #000;
    padding: 0 15px;
    margin: 0 0 20px;
  }
  .list-agency .price-value .amount {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    color: #f89923;
  }
  .list-agency .price-value .duration {
    font-size: 30px;
    font-weight: 300;
    text-transform: lowercase;
  }
  .list-agency .pricing-content {
    text-align: left;
    padding: 25px 15px;
    margin: 0 0 30px;
    border: 1px solid #e7e7e7;
    border-left: none;
    border-right: none;
    list-style: none;
    display: inline-block;
  }
  .list-agency .pricing-content li {
    color: #777;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    padding: 0 0 0 40px;
    margin: 0 0 12px;
    position: relative;
  }
  .list-agency .pricing-content li:last-child {
    margin-bottom: 0;
  }
  .list-agency .pricing-content li:before {
    content: '\f00c';
    color: #fff;
    background: #f89923;
    font-family: 'Font Awesome 5 Free';
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    line-height: 24px;
    width: 23px;
    height: 23px;
    border-radius: 50px;
    position: absolute;
    top: 1px;
    left: 0;
  }
  .list-agency .pricing-content li.disable:before {
    content: '\f00d';
    background: #ed1925;
  }
  .list-agency .pricingTable-signup button {
    color: #fff;
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
    border-radius: 2rem;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 7px 15px;
    border: none;
    display: inline-block;
    transition: all 0.3s ease;
  }
  .list-agency .pricingTable-signup button:disabled {
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #880202, #c51414);
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .list-agency .pricingTable-signup a:hover {
    text-shadow: 4px 4px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.15);
  }
  .list-agency .pricingTable-header {
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
  }
  .pricingTable .pricing-content li:before {
    background: #54a445;
  }
  .pricingTable .pricing-content li.disable:before {
    background: #ed1925;
  }
  .pricingTable-header {
    text-align: center;
    text-transform: uppercase;
    padding: 0px 0;
    margin: 0 20px 20px 0;
    clip-path: polygon(0 0, 0 100%, 85% 100%, 100% 50%, 85% 0);
  }
  .img-div {
    flex: 45%;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }
  .title {
    text-align: center;
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #022688, #143dc5);
    padding: 5px 0;
    font-size: 15px;
    font-weight: 700;
    color: white;
    border-radius: 15px;
  }
  .img-div img {
    max-width: 100px;
    max-height: 140px;
  }
  .detail-div {
    flex: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    align-self: center;
    font-size: 30px;
    font-weight: 800;
    color: #ffa300;
  }
  .button-custom {
    height: 30px;
    background: #c8973b;
    border-top-right-radius: 5px;
    border: 0 none !important;
    border-bottom-right-radius: 5px;
    color: white;
    max-width: 40%;
    margin: 0 !important;
    padding: 0 5px;
    font-size: 15px;
  }
  .input-custom {
    width: 60%;
    height: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0 none !important;
    text-align: center;
    font-size: 15px;
    padding: 0;
  }
  .widget {
    min-height: 155px;
  }
  .box-VipClub-content {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .game-title-2 {
    color: #ffa300;

    font-weight: 800;
  }
  .mx-2 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .game {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    justify-self: center;

    padding-bottom: 5px;

    width: 100%;
  }

  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    padding-right: 15px;
    @media (max-width: 500px) {
      padding-right: 0px;
    }
  }
  .right-content .group-button .btn {
    min-width: 90px;
  }
  .left-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    align-self: center;
    padding-left: 15px;
    flex-direction: column;
    @media (max-width: 500px) {
      padding-left: 0px;
    }
  }

  @media (max-width: 500px) {
    .game {
      flex-direction: column;
      border-bottom: 2px solid #c8973b;
    }
  }
  .game-title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .game-balance {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
  }
</style>
